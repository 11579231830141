const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class NetworkService {
  getOTP(mobile) {
    const url = `${BASE_URL}/otp`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        mobile: mobile
      })
    });
  }

  resolveCode(code) {
    const url = `${BASE_URL}/codes/${code}`;
    return fetch(url);
  }

  castVote(payload) {
    const body = Object.assign(
      {},
      ...[
        "ageGroup",
        "capacity",
        "choice",
        "education",
        "gender",
        "level",
        "reason",
        "school",
        "type"
      ].map(key => ({
        [key]: payload[key]
      }))
    );
    if (payload.type === "otp") {
      body.mobile = payload.mobile;
      body.otp = payload.otp;
      if (body.capacity !== "學生") {
        delete body.education;
        delete body.level;
      }
    } else if (payload.type === "code") {
      body.code = payload.code.toUpperCase();
      delete body.school;
    }

    console.log(body);
    const url = `${BASE_URL}/votes`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }
}

export default new NetworkService();
