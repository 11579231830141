import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Button, Container } from "reactstrap";
import { Link } from "react-router-dom";

import Poster from "../components/Poster";
import VideoContainer from "../components/VideoContainer";
import poster from "../images/poster.jpg";

const Home = props => {
  useEffect(() => {
    document.body.setAttribute("role", "home");

    return () => {
      document.body.setAttribute("role", "");
    };
  }, []);

  return (
    <>
      <Row>
        <Container
          style={{
            top: 0,
            backgroundColor: "#ff5900",
            color: "#ffffff",
            fontWeight: 900,
            marginBottom: ".5rem"
          }}
          className="text-center"
        >
          <h2>網上投票經已完滿結束，多謝支持</h2>
          結果將於點票後上載至本中心網站，如有問題，歡迎致電查詢
        </Container>
      </Row>
      <Row>
        <Col md={{ size: 4 }} className="order-md-first order-sm-last">
          <Poster src={poster} alt="海報" />
        </Col>
        <Col md={{ size: 4 }}>
          <VideoContainer src="https://www.youtube-nocookie.com/embed/6Ll_aWM8L1w" />
        </Col>
        <Col md={{ size: 4 }}>
          <h3 style={{ color: "#bb5701", fontWeight: "700" }}>
            立即為「2019年度中國歷史人物選舉」投下你的一票
          </h3>

          <Link to="/voting">
            <Button
              style={{
                backgroundColor: "#ff5900",
                borderColor: "#ff5900",
                color: "#ffffff",
                fontWeight: "bold",
                marginBottom: ".5rem"
              }}
              disabled
            >
              立即投票
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default Home;
