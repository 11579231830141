import React from "react";
import ReactSelect from "react-select";

// import "./SelectWrapper.css";

// wrapper component to work with Formik
class SelectWrapper extends React.Component {
  handleChange = value => {
    // this is going to call setFieldValue and manually update values[field]
    this.props.onChange(this.props.field, value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched[field]
    this.props.onBlur(this.props.field, true);
  };

  render() {
    const { onChange, onBlur, ...rest } = this.props;
    return (
      <ReactSelect
        {...rest}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    );
  }
}

export default SelectWrapper;
