import React from "react";
import { Link } from "react-router-dom";

import "./DebugNav.css";

const DebugNav = () => {
  if (process.env.NODE_ENV === "production") {
    return null;
  }
  return (
    <nav className="DebugNav text-center">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        |
        <li>
          <Link to="/voting">Voting</Link>
        </li>
        |
        <li>
          <Link to="/g">Code Voting</Link>
        </li>
      </ul>
      <span className="float-left">debug</span>
      <span className="float-right">debug</span>
      <hr />
    </nav>
  );
};

export default DebugNav;
