import React from "react";
import { Row, Col } from "reactstrap";

import "./Footer.css";

export default () => {
  return (
    <div className="Footer">
      <hr />
      <Row>
        <Col>
          地址：
          <br className="rwd-break-md" />
          香港西營盤德輔道西246號東慈商業中心1901-1902室
        </Col>
      </Row>
      <Row>
        <Col sm="6" lg="3">
          電話：3468 5980
        </Col>
        <Col sm="6" lg="3">
          傳真：3586 0209
        </Col>
        <Col sm="6" lg="3">
          電郵：
          <a href="mailto:info@cnhe-hk.org">info@cnhe-hk.org</a>
        </Col>
        <Col sm="6" lg="3">
          網址：
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cnhe-hk.org"
          >
            www.cnhe-hk.org
          </a>
        </Col>
      </Row>
    </div>
  );
};
