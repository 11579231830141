import React, { PureComponent } from "react";
import { FormGroup, FormText, Label, Input } from "reactstrap";
import Error from "./Error";

class Code extends PureComponent {
  render() {
    const {
      field,
      values,
      errors,
      touched,
      handleChange,
      handleBlur
    } = this.props;
    return (
      <FormGroup>
        <Label htmlFor={field}>短代碼</Label>
        <Input
          id={field}
          placeholder="請輸入6字投票短代碼"
          value={values[field]}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
        />
        <FormText>
          請輸入小票上的投票短代碼，由<b>大寫字母和數字</b>組成
        </FormText>
        <Error field={field} errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default Code;
