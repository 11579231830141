import React, { Component } from "react";
import { FormGroup, Label } from "reactstrap";
import { FixedSizeList as List } from "react-window";

import schools from "../schools";
import Error from "./Error";
import SelectWrapper from "./SelectWrapper";

const ITEM_HEIGHT = 36;
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * ITEM_HEIGHT;

    return (
      <List
        height={maxHeight}
        itemCount={React.Children.count(children)}
        itemSize={ITEM_HEIGHT}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

class School extends Component {
  shouldComponentUpdate(nextProps) {
    const { field, values, errors, touched } = this.props;

    if (values[field].value !== nextProps.values[field].value) {
      return true;
    }
    if (errors[field] !== nextProps.errors[field]) {
      return true;
    }
    if (touched[field] !== nextProps.touched[field]) {
      return true;
    }
    // console.log(values[field], nextProps.values[field]);
    return false;
  }
  render() {
    const {
      field,
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      isDisabled,
      placeholder
    } = this.props;
    return (
      <FormGroup>
        <Label htmlFor={field}>所屬學校</Label>
        <SelectWrapper
          id={field}
          field={field}
          components={{ MenuList }}
          options={schools}
          value={values[field]}
          isDisabled={isDisabled}
          placeholder={
            placeholder || "請選擇所屬學校，或可直接輸入學校名稱作篩選"
          }
          onChange={setFieldValue}
          onBlur={setFieldTouched}
        />
        <Error field={field} errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default School;
