const schools = [
  "[中西區] CARMEL SCHOOL",
  "[中西區] GERMAN SWISS INTERNATIONAL SCHOOL",
  "[中西區] ISLAND CHRISTIAN ACADEMY",
  "[中西區] 中西區聖安多尼學校",
  "[中西區] 嘉諾撒聖心學校",
  "[中西區] 嘉諾撒聖心學校私立部",
  "[中西區] 天主教總堂區學校",
  "[中西區] 山頂小學",
  "[中西區] 己連拿小學",
  "[中西區] 救恩學校",
  "[中西區] 新會商會學校",
  "[中西區] 李陞小學",
  "[中西區] 樂善堂梁銶琚書院",
  "[中西區] 港島中學",
  "[中西區] 港灣學校",
  "[中西區] 聖保羅書院",
  "[中西區] 聖保羅男女中學",
  "[中西區] 聖公會呂明才紀念小學",
  "[中西區] 聖公會基恩小學",
  "[中西區] 聖公會聖彼得小學",
  "[中西區] 聖公會聖馬太小學",
  "[中西區] 聖嘉勒女書院",
  "[中西區] 聖嘉勒小學",
  "[中西區] 聖嘉祿學校",
  "[中西區] 聖士提反堂中學",
  "[中西區] 聖士提反女子中學",
  "[中西區] 聖士提反女子中學附屬小學",
  "[中西區] 聖安多尼學校",
  "[中西區] 聖若瑟英文書院",
  "[中西區] 聖類斯中學",
  "[中西區] 聖類斯中學(小學部)",
  "[中西區] 般咸道官立小學",
  "[中西區] 英皇書院",
  "[中西區] 英皇書院同學會小學",
  "[中西區] 英皇書院同學會小學第二校",
  "[中西區] 英華女學校",
  "[中西區] 香島華德福學校",
  "[中西區] 香港潮商學校",
  "[中西區] 高主教書院",
  "[九龍城區] AMERICAN INTERNATIONAL SCH (PRI BRANCH)",
  "[九龍城區] STAMFORD AMERICAN SCHOOL HONG KONG",
  "[九龍城區] 中華基督教會基華小學(九龍塘)",
  "[九龍城區] 中華基督教會基道中學",
  "[九龍城區] 中華基督教會灣仔堂基道小學(九龍城)",
  "[九龍城區] 九龍塘天主教華德學校",
  "[九龍城區] 九龍塘學校",
  "[九龍城區] 九龍塘學校(中學部)",
  "[九龍城區] 九龍塘官立小學",
  "[九龍城區] 九龍塘宣道小學",
  "[九龍城區] 九龍小學",
  "[九龍城區] 九龍真光中學",
  "[九龍城區] 九龍真光中學(小學部)",
  "[九龍城區] 九龍靈光小學",
  "[九龍城區] 五旬節中學",
  "[九龍城區] 京斯敦國際學校",
  "[九龍城區] 何文田官立中學",
  "[九龍城區] 何明華會督銀禧中學",
  "[九龍城區] 余振強紀念中學",
  "[九龍城區] 保良局何壽南小學",
  "[九龍城區] 保良局林文燦英文小學",
  "[九龍城區] 保良局陳維周夫人紀念學校",
  "[九龍城區] 保良局顏寶鈴書院",
  "[九龍城區] 創知中學",
  "[九龍城區] 協恩中學",
  "[九龍城區] 協恩中學附屬小學",
  "[九龍城區] 合一堂學校",
  "[九龍城區] 啟思小學",
  "[九龍城區] 喇沙小學",
  "[九龍城區] 喇沙書院",
  "[九龍城區] 嘉諾撒聖家學校",
  "[九龍城區] 嘉諾撒聖家學校(九龍塘)",
  "[九龍城區] 嘉諾撒聖家書院",
  "[九龍城區] 基督教女青年會丘佐榮中學",
  "[九龍城區] 基督教香港信義會紅磡信義學校",
  "[九龍城區] 天主教領島學校",
  "[九龍城區] 天保民學校",
  "[九龍城區] 天神嘉諾撒學校",
  "[九龍城區] 德蘭中學",
  "[九龍城區] 愛培學校",
  "[九龍城區] 拔萃小學",
  "[九龍城區] 拔萃男書院",
  "[九龍城區] 新亞中學",
  "[九龍城區] 旅港開平商會中學",
  "[九龍城區] 朗思國際學校",
  "[九龍城區] 東華三院黃笏南中學",
  "[九龍城區] 樂善堂小學",
  "[九龍城區] 民生書院",
  "[九龍城區] 民生書院小學",
  "[九龍城區] 獻主會小學",
  "[九龍城區] 獻主會聖馬善樂小學",
  "[九龍城區] 瑪利諾修院學校(中學部)",
  "[九龍城區] 瑪利諾修院學校(小學部)",
  "[九龍城區] 畢架山小學",
  "[九龍城區] 神召第一小學暨幼稚園",
  "[九龍城區] 禮賢會彭學高紀念中學",
  "[九龍城區] 美國國際學校",
  "[九龍城區] 耀中國際學校",
  "[九龍城區] 耀中國際學校(中學)",
  "[九龍城區] 耀山學校",
  "[九龍城區] 聖三一堂小學",
  "[九龍城區] 聖公會奉基千禧小學",
  "[九龍城區] 聖公會奉基小學",
  "[九龍城區] 聖公會牧愛小學",
  "[九龍城區] 聖公會聖三一堂中學",
  "[九龍城區] 聖公會聖匠中學",
  "[九龍城區] 聖公會聖匠小學",
  "[九龍城區] 聖公會聖十架小學",
  "[九龍城區] 聖公會聖提摩太小學",
  "[九龍城區] 聖公會蔡功譜中學",
  "[九龍城區] 聖母院書院",
  "[九龍城區] 聖羅撒學校",
  "[九龍城區] 聖若望英文書院(小學部)",
  "[九龍城區] 英皇佐治五世學校",
  "[九龍城區] 華英中學",
  "[九龍城區] 葛量洪校友會黃埔學校",
  "[九龍城區] 藝術與科技教育中心",
  "[九龍城區] 賽馬會善樂學校",
  "[九龍城區] 賽馬會官立中學",
  "[九龍城區] 農圃道官立小學",
  "[九龍城區] 迦密中學",
  "[九龍城區] 鄧鏡波學校",
  "[九龍城區] 陳瑞祺(喇沙)小學",
  "[九龍城區] 陳瑞祺(喇沙)書院",
  "[九龍城區] 順德聯誼總會胡兆熾中學",
  "[九龍城區] 香港兆基創意書院(李兆基基金會贊助、香港當代文化中心主辦)",
  "[九龍城區] 香港培正中學",
  "[九龍城區] 香港培正小學",
  "[九龍城區] 香港培道中學",
  "[九龍城區] 香港培道小學",
  "[九龍城區] 香港澳洲國際學校",
  "[九龍城區] 香港紅十字會醫院學校",
  "[九龍城區] 馬頭涌官立小學",
  "[九龍城區] 馬頭涌官立小學(紅磡灣)",
  "[九龍城區] 黃埔宣道小學",
  "[元朗區] 中華基督教會元朗真光小學",
  "[元朗區] 中華基督教會基元中學",
  "[元朗區] 中華基督教會基朗中學",
  "[元朗區] 中華基督教會方潤華中學",
  "[元朗區] 中華基督教會方潤華小學",
  "[元朗區] 中華基督教青年會中學",
  "[元朗區] 中華基督教青年會小學",
  "[元朗區] 伊利沙伯中學舊生會中學",
  "[元朗區] 伊利沙伯中學舊生會小學",
  "[元朗區] 伊利沙伯中學舊生會小學分校",
  "[元朗區] 伊利沙伯中學舊生會湯國華中學",
  "[元朗區] 伯特利中學",
  "[元朗區] 伯裘書院",
  "[元朗區] 佛教榮茵學校",
  "[元朗區] 佛教茂峰法師紀念中學",
  "[元朗區] 佛教陳榮根紀念學校",
  "[元朗區] 保良局羅氏信託學校",
  "[元朗區] 元朗公立中學",
  "[元朗區] 元朗公立中學校友會小學",
  "[元朗區] 元朗公立中學校友會英業小學",
  "[元朗區] 元朗公立中學校友會鄧兆棠中學",
  "[元朗區] 元朗商會中學",
  "[元朗區] 元朗商會小學",
  "[元朗區] 元朗天主教中學",
  "[元朗區] 元朗官立小學",
  "[元朗區] 元朗寶覺小學",
  "[元朗區] 元朗朗屏邨惠州學校",
  "[元朗區] 元朗朗屏邨東莞學校",
  "[元朗區] 光明學校",
  "[元朗區] 光明英來學校",
  "[元朗區] 八鄉中心小學",
  "[元朗區] 匡智元朗晨曦學校",
  "[元朗區] 匡智元朗晨樂學校",
  "[元朗區] 十八鄉鄉事委員會公益社中學",
  "[元朗區] 十八鄉鄉事委員會公益社小學",
  "[元朗區] 南元朗官立小學",
  "[元朗區] 博愛醫院歷屆總理聯誼會梁省德學校",
  "[元朗區] 博愛醫院鄧佩瓊紀念中學",
  "[元朗區] 可道中學(嗇色園主辦)",
  "[元朗區] 和富慈善基金李宗德小學",
  "[元朗區] 嗇色園主辦可銘學校",
  "[元朗區] 圓玄學院妙法寺內明陳呂重德紀念中學",
  "[元朗區] 基督教宣道會徐澤林紀念小學",
  "[元朗區] 基督教香港信義會元朗信義中學",
  "[元朗區] 基督教香港信義會啟信學校",
  "[元朗區] 基督教香港信義會宏信書院",
  "[元朗區] 天主教培聖中學",
  "[元朗區] 天主教崇德英文書院",
  "[元朗區] 天水圍天主教小學",
  "[元朗區] 天水圍官立中學",
  "[元朗區] 天水圍官立小學",
  "[元朗區] 天水圍循道衛理小學",
  "[元朗區] 天水圍循道衞理中學",
  "[元朗區] 天水圍香島中學",
  "[元朗區] 學信書院",
  "[元朗區] 宣道會葉紹蔭紀念小學",
  "[元朗區] 惇裕學校",
  "[元朗區] 新界鄉議局元朗區中學",
  "[元朗區] 明愛元朗陳震夏中學",
  "[元朗區] 明愛樂勤學校",
  "[元朗區] 東華三院姚達之紀念小學(元朗)",
  "[元朗區] 東華三院李東海小學",
  "[元朗區] 東華三院盧幹庭紀念中學",
  "[元朗區] 東華三院郭一葦中學",
  "[元朗區] 東華三院馬振玉紀念中學",
  "[元朗區] 樂善堂梁銶琚學校",
  "[元朗區] 樂善堂梁銶琚學校(分校)",
  "[元朗區] 港澳信義會黃陳淑英紀念學校",
  "[元朗區] 潮陽百欣小學",
  "[元朗區] 激活英文小學",
  "[元朗區] 獅子會何德心小學",
  "[元朗區] 穆民國際小學",
  "[元朗區] 聖公會天水圍靈愛小學",
  "[元朗區] 聖公會白約翰會督中學",
  "[元朗區] 聖公會錦田聖約瑟小學",
  "[元朗區] 聖公會靈愛小學",
  "[元朗區] 裘錦秋中學(元朗)",
  "[元朗區] 賽馬會毅智書院",
  "[元朗區] 趙聿修紀念中學",
  "[元朗區] 路德會西門英才中學",
  "[元朗區] 路德會西門英才夜校",
  "[元朗區] 通德學校",
  "[元朗區] 道慈佛社楊日霖紀念學校",
  "[元朗區] 遵理學校",
  "[元朗區] 金巴崙長老會耀道中學",
  "[元朗區] 金巴崙長老會耀道小學",
  "[元朗區] 錦田公立蒙養學校",
  "[元朗區] 鐘聲學校",
  "[元朗區] 順德聯誼總會伍冕端小學",
  "[元朗區] 順德聯誼總會翁祐中學",
  "[元朗區] 香港中文大學校友會聯會張煊昌中學",
  "[元朗區] 香港學生輔助會小學",
  "[元朗區] 香港普通話研習社科技創意小學",
  "[元朗區] 香港潮陽小學",
  "[元朗區] 香港管理專業協會羅桂祥中學",
  "[元朗區] 香港青年協會李兆基小學",
  "[元朗區] 香港青年協會李兆基書院",
  "[北區] INTERNATIONAL COLLEGE HONG KONG (NT)",
  "[北區] 上水官立中學",
  "[北區] 上水宣道小學",
  "[北區] 上水惠州公立學校",
  "[北區] 中華基督教會基新中學",
  "[北區] 五旬節于良發小學",
  "[北區] 五旬節靳茂生小學",
  "[北區] 保良局馬錦明中學",
  "[北區] 基督教粉嶺神召會小學",
  "[北區] 基督教香港信義會心誠中學",
  "[北區] 宣道會陳朱素華紀念中學",
  "[北區] 寶血會培靈學校",
  "[北區] 打鼓嶺嶺英公立學校",
  "[北區] 救世軍石湖學校",
  "[北區] 新界喇沙中學",
  "[北區] 方樹福堂基金方樹泉小學",
  "[北區] 明愛粉嶺陳震夏中學",
  "[北區] 曾梅千禧學校",
  "[北區] 李志達紀念學校",
  "[北區] 東莞學校",
  "[北區] 東華三院李嘉誠中學",
  "[北區] 東華三院港九電器商聯會小學",
  "[北區] 東華三院甲寅年總理中學",
  "[北區] 東華三院馬錦燦紀念小學",
  "[北區] 沙頭角中心學校",
  "[北區] 田家炳中學",
  "[北區] 石湖墟公立學校",
  "[北區] 福德學社小學",
  "[北區] 粉嶺公立學校",
  "[北區] 粉嶺官立中學",
  "[北區] 粉嶺官立小學",
  "[北區] 粉嶺救恩書院",
  "[北區] 粉嶺禮賢會中學",
  "[北區] 聖公會嘉福榮真小學",
  "[北區] 聖公會榮真小學",
  "[北區] 聖公會陳融中學",
  "[北區] 聖芳濟各書院",
  "[北區] 育賢學校",
  "[北區] 金錢村何東學校",
  "[北區] 風采中學(教育評議會主辦)",
  "[北區] 香海正覺蓮社佛教普光學校",
  "[北區] 香海正覺蓮社佛教正慧小學",
  "[北區] 香海正覺蓮社佛教正覺蓮社學校",
  "[北區] 香海正覺蓮社佛教陳式宏學校",
  "[北區] 香海正覺蓮社佛教馬錦燦紀念英文中學",
  "[北區] 香港紅十字會醫院學校",
  "[北區] 香港道教聯合會鄧顯紀念中學",
  "[北區] 鳳溪創新小學",
  "[北區] 鳳溪廖潤琛紀念學校",
  "[北區] 鳳溪廖萬石堂中學",
  "[北區] 鳳溪第一中學",
  "[北區] 鳳溪第一小學",
  "[南區] GERMAN SWISS INTERNATIONAL SCHOOL",
  "[南區] HONG KONG INTERNATIONAL SCHOOL",
  "[南區] KELLETT SCHOOL",
  "[南區] 余振強紀念第二中學",
  "[南區] 加拿大國際學校",
  "[南區] 南島中學",
  "[南區] 嘉諾撒培德學校",
  "[南區] 嘉諾撒培德書院",
  "[南區] 嘉諾撒聖心書院",
  "[南區] 培英中學",
  "[南區] 堅尼地小學",
  "[南區] 弘立書院",
  "[南區] 心光學校",
  "[南區] 心光恩望學校",
  "[南區] 新加坡國際學校",
  "[南區] 新會商會陳白沙紀念中學",
  "[南區] 明愛胡振中中學",
  "[南區] 明愛莊月明中學",
  "[南區] 東華三院徐展堂學校",
  "[南區] 東華三院鶴山學校",
  "[南區] 海怡寶血小學",
  "[南區] 港大同學會書院",
  "[南區] 港灣學校",
  "[南區] 滬江維多利亞學校",
  "[南區] 漢鼎書院",
  "[南區] 瑪利灣學校",
  "[南區] 聖伯多祿中學",
  "[南區] 聖伯多祿天主教小學",
  "[南區] 聖保羅書院小學",
  "[南區] 聖保羅男女中學附屬小學",
  "[南區] 聖公會呂明才中學",
  "[南區] 聖公會田灣始南小學",
  "[南區] 聖公會置富始南小學",
  "[南區] 聖士提反書院",
  "[南區] 聖士提反書院附屬小學",
  "[南區] 華富邨寶血小學",
  "[南區] 蒙特梭利國際學校",
  "[南區] 西島中學",
  "[南區] 香島道官立小學",
  "[南區] 香港仔工業學校",
  "[南區] 香港仔浸信會呂明才書院",
  "[南區] 香港仔聖伯多祿天主教小學",
  "[南區] 香港南區官立小學",
  "[南區] 香港真光書院",
  "[南區] 香港紅十字會甘迺迪中心",
  "[南區] 香港紅十字會醫院學校",
  "[南區] 香港航海學校",
  "[南區] 香港青少年培育會陳南昌紀念學校",
  "[南區] 鴨脷洲街坊學校",
  "[大埔區] AMERICAN SCHOOL HONG KONG",
  "[大埔區] INT'L COLLEGE HK HONG LOK YUEN (PRISEC)",
  "[大埔區] JAPANESE INTERNATIONAL SCHOOL",
  "[大埔區] NORWEGIAN INTERNATIONAL SCHOOL",
  "[大埔區] 三水同鄉會禤景榮學校",
  "[大埔區] 中華基督教會馮梁結紀念中學",
  "[大埔區] 中華聖潔會靈風中學",
  "[大埔區] 仁濟醫院蔡衍濤小學",
  "[大埔區] 佛教大光慈航中學",
  "[大埔區] 保良局田家炳千禧小學",
  "[大埔區] 保良局田家炳小學",
  "[大埔區] 匡智松嶺學校",
  "[大埔區] 匡智松嶺第三校",
  "[大埔區] 匡智松嶺第二校",
  "[大埔區] 南亞路德會沐恩中學",
  "[大埔區] 大埔三育中學",
  "[大埔區] 大埔官立小學",
  "[大埔區] 大埔崇德黃建常紀念學校",
  "[大埔區] 大埔循道衛理小學",
  "[大埔區] 大埔浸信會公立學校",
  "[大埔區] 大埔舊墟公立學校",
  "[大埔區] 大埔舊墟公立學校(寶湖道)",
  "[大埔區] 天主教聖母聖心小學",
  "[大埔區] 孔教學院何郭佩珍中學",
  "[大埔區] 德萃小學",
  "[大埔區] 恩主教書院",
  "[大埔區] 救恩書院",
  "[大埔區] 新界婦孺福利會梁省德學校",
  "[大埔區] 新界鄉議局大埔區中學",
  "[大埔區] 林村公立黃福鑾紀念學校",
  "[大埔區] 港九街坊婦女會孫方中小學",
  "[大埔區] 港九街坊婦女會孫方中書院",
  "[大埔區] 王肇枝中學",
  "[大埔區] 神召會康樂中學",
  "[大埔區] 神召會康樂夜中學",
  "[大埔區] 羅定邦中學",
  "[大埔區] 聖公會莫壽增會督中學",
  "[大埔區] 聖公會阮鄭夢芹小學",
  "[大埔區] 聖公會阮鄭夢芹銀禧小學",
  "[大埔區] 迦密柏雨中學",
  "[大埔區] 迦密聖道中學",
  "[大埔區] 靈糧堂劉梅軒中學",
  "[大埔區] 香港教師會夜中學",
  "[大埔區] 香港教師會李興貴中學",
  "[大埔區] 香港教育學院賽馬會小學",
  "[大埔區] 香港紅十字會醫院學校",
  "[大埔區] 香港紅卍字會大埔卍慈中學",
  "[大埔區] 香港耀能協會賽馬會田綺玲學校",
  "[大埔區] 香港道教聯合會圓玄學院第二中學",
  "[大埔區] 香港道教聯合會雲泉吳禮和紀念學校",
  "[屯門區] 世界龍岡學校劉德容紀念小學",
  "[屯門區] 中華基督教會何福堂小學",
  "[屯門區] 中華基督教會何福堂書院",
  "[屯門區] 中華基督教會拔臣小學",
  "[屯門區] 中華基督教會蒙黃花沃紀念小學",
  "[屯門區] 中華基督教會譚李麗芬紀念中學",
  "[屯門區] 五邑鄒振猷學校",
  "[屯門區] 仁德天主教小學",
  "[屯門區] 仁愛堂劉皇發夫人小學",
  "[屯門區] 仁愛堂田家炳中學",
  "[屯門區] 仁愛堂陳黃淑芳紀念中學",
  "[屯門區] 仁濟醫院何式南小學",
  "[屯門區] 仁濟醫院第二中學",
  "[屯門區] 仁濟醫院羅陳楚思小學",
  "[屯門區] 伊斯蘭學校",
  "[屯門區] 佛教沈香林紀念中學",
  "[屯門區] 保良局志豪小學",
  "[屯門區] 保良局方王錦全小學",
  "[屯門區] 保良局梁周順琴小學",
  "[屯門區] 保良局百周年李兆忠紀念中學",
  "[屯門區] 保良局莊啟程第二小學",
  "[屯門區] 保良局董玉娣中學",
  "[屯門區] 保良局西區婦女福利會馮李佩瑤小學",
  "[屯門區] 保良局香港道教聯合會圓玄小學",
  "[屯門區] 僑港伍氏宗親會伍時暢紀念學校",
  "[屯門區] 加拿大神召會嘉智中學",
  "[屯門區] 匡智屯門晨崗學校",
  "[屯門區] 匡智屯門晨曦學校",
  "[屯門區] 匡智屯門晨輝學校",
  "[屯門區] 南屯門官立中學",
  "[屯門區] 博愛醫院歷屆總理聯誼會鄭任安夫人千禧小學",
  "[屯門區] 博愛醫院歷屆總理聯誼會鄭任安夫人學校",
  "[屯門區] 台山商會學校",
  "[屯門區] 哈羅香港國際學校",
  "[屯門區] 嗇色園主辦可藝中學",
  "[屯門區] 妙法寺劉金龍中學",
  "[屯門區] 宣道中學",
  "[屯門區] 屯門天主教中學",
  "[屯門區] 屯門官立中學",
  "[屯門區] 屯門官立小學",
  "[屯門區] 崇真書院",
  "[屯門區] 廠商會蔡章閣中學",
  "[屯門區] 恩平工商會李琳明中學",
  "[屯門區] 新會商會中學",
  "[屯門區] 新生命教育協會平安福音中學",
  "[屯門區] 明愛屯門馬登基金中學",
  "[屯門區] 東華三院辛亥年總理中學",
  "[屯門區] 東華三院邱子田紀念中學",
  "[屯門區] 東華三院鄧肇堅小學",
  "[屯門區] 東華三院鄺錫坤伉儷中學",
  "[屯門區] 柏立基教育學院校友會何壽基學校",
  "[屯門區] 樂善堂梁黃蕙芳紀念學校",
  "[屯門區] 浸信會永隆中學",
  "[屯門區] 深培中學",
  "[屯門區] 港專成人教育中心(屯門官立中學)",
  "[屯門區] 聖公會聖西門呂明才中學",
  "[屯門區] 聖公會蒙恩小學",
  "[屯門區] 興德學校",
  "[屯門區] 裘錦秋中學(屯門)",
  "[屯門區] 路德會呂祥光中學",
  "[屯門區] 路德會呂祥光小學",
  "[屯門區] 迦密唐賓南紀念中學",
  "[屯門區] 道教青松小學",
  "[屯門區] 道教青松小學(湖景邨)",
  "[屯門區] 鄉師自然學校",
  "[屯門區] 鐘聲慈善社胡陳金枝中學",
  "[屯門區] 青山天主教小學",
  "[屯門區] 青松侯寶垣中學",
  "[屯門區] 順德聯誼總會何日東小學",
  "[屯門區] 順德聯誼總會李金小學",
  "[屯門區] 順德聯誼總會梁銶琚中學",
  "[屯門區] 順德聯誼總會胡少渠紀念小學",
  "[屯門區] 順德聯誼總會譚伯羽中學",
  "[屯門區] 香海正覺蓮社佛教梁植偉中學",
  "[屯門區] 香港九龍塘基督教中華宣道會陳瑞芝紀念中學",
  "[屯門區] 香港基督教服務處培愛學校",
  "[屯門區] 香港紅十字會醫院學校",
  "[屯門區] 香港紅卍字會屯門卍慈小學",
  "[屯門區] 香港路德會增城兆霖學校",
  "[屯門區] 馬錦明慈善基金馬可賓紀念中學",
  "[東區] CARMEL SCHOOL",
  "[東區] HONGKONG JAPANESE SCHOOL",
  "[東區] KOREAN INTERNATIONAL SCHOOL",
  "[東區] LYC'EE FRANCAIS INTL (FRENCH INTL SCH)",
  "[東區] 中華傳道會劉永生中學",
  "[東區] 中華基督教會基灣小學",
  "[東區] 中華基督教會基灣小學(愛蝶灣)",
  "[東區] 中華基督教會桂華山中學",
  "[東區] 中華基金中學",
  "[東區] 丹拿山循道學校",
  "[東區] 伊斯蘭脫維善紀念中學",
  "[東區] 佛教中華康山學校",
  "[東區] 保良局余李慕芬紀念學校",
  "[東區] 勵志會梁李秀娛紀念小學",
  "[東區] 北角官立小學",
  "[東區] 北角循道學校",
  "[東區] 北角衛理小學",
  "[東區] 啟基學校(港島)",
  "[東區] 嘉諾撒書院",
  "[東區] 地利亞(加拿大)學校",
  "[東區] 地利亞預科夜班(太古城)",
  "[東區] 培僑中學",
  "[東區] 培僑小學",
  "[東區] 培生學校",
  "[東區] 基督教香港信義會信愛學校",
  "[東區] 天主教明德學校",
  "[東區] 太古小學",
  "[東區] 寶血女子中學",
  "[東區] 嶺南中學",
  "[東區] 嶺南衡怡紀念中學",
  "[東區] 庇理羅士女子中學",
  "[東區] 張振興伉儷書院",
  "[東區] 張祝珊英文中學",
  "[東區] 愛秩序灣官立小學",
  "[東區] 慈幼學校",
  "[東區] 慈幼英文學校",
  "[東區] 救世軍中原慈善基金學校",
  "[東區] 救世軍韋理夫人紀念學校",
  "[東區] 文理書院(香港)",
  "[東區] 明愛柴灣馬登基金中學",
  "[東區] 明愛樂義學校",
  "[東區] 港大同學會小學",
  "[東區] 港專成人教育中心(筲箕灣官立中學)",
  "[東區] 港島民生書院",
  "[東區] 滬江小學",
  "[東區] 漢基國際學校",
  "[東區] 漢華中學",
  "[東區] 漢華中學(小學部)",
  "[東區] 炮台山循道衛理中學",
  "[東區] 福建中學(小西灣)",
  "[東區] 筲箕灣官立中學",
  "[東區] 筲箕灣官立小學",
  "[東區] 筲箕灣崇真學校",
  "[東區] 筲箕灣東官立中學",
  "[東區] 聖公會李福慶中學",
  "[東區] 聖公會柴灣聖米迦勒小學",
  "[東區] 聖公會聖米迦勒小學",
  "[東區] 聖貞德中學",
  "[東區] 聖馬可中學",
  "[東區] 聖馬可小學",
  "[東區] 蘇浙公學",
  "[東區] 蘇浙小學",
  "[東區] 衛理中學",
  "[東區] 金文泰中學",
  "[東區] 閩僑中學",
  "[東區] 顯理中學",
  "[東區] 香港中國婦女會丘佐榮學校",
  "[東區] 香港中國婦女會中學",
  "[東區] 香港嘉諾撒學校",
  "[東區] 香港紅十字會醫院學校",
  "[東區] 香港西區扶輪社匡智晨輝學校",
  "[東區] 鰂魚涌小學",
  "[沙田區] 世界龍岡學校黃耀南小學",
  "[沙田區] 九龍城浸信會禧年(恩平)小學",
  "[沙田區] 九龍城浸信會禧年小學",
  "[沙田區] 五旬節林漢光中學",
  "[沙田區] 五育中學",
  "[沙田區] 仁濟醫院董之英紀念中學",
  "[沙田區] 佛教覺光法師中學",
  "[沙田區] 佛教黃允畋中學",
  "[沙田區] 保良局思培基金香港欖球總會中學教育服務中心",
  "[沙田區] 保良局朱敬文中學",
  "[沙田區] 保良局朱正賢小學",
  "[沙田區] 保良局王賜豪(田心谷)小學",
  "[沙田區] 保良局胡忠中學",
  "[沙田區] 保良局莊啟程小學",
  "[沙田區] 保良局蕭漢森小學",
  "[沙田區] 保良局雨川小學",
  "[沙田區] 博愛醫院陳楷紀念中學",
  "[沙田區] 台山商會中學",
  "[沙田區] 吳氏宗親總會泰伯紀念學校",
  "[沙田區] 啓新書院",
  "[沙田區] 培僑書院",
  "[沙田區] 培基小學",
  "[沙田區] 基督教國際學校",
  "[沙田區] 基督教香港信義會禾輋信義學校",
  "[沙田區] 基督教香港信義會馬鞍山信義學校",
  "[沙田區] 基督書院",
  "[沙田區] 天主教聖華學校",
  "[沙田區] 天主教郭得勝中學",
  "[沙田區] 安菲爾學校",
  "[沙田區] 循理會白普理基金循理小學",
  "[沙田區] 循理會美林小學",
  "[沙田區] 德信中學",
  "[沙田區] 慈航學校",
  "[沙田區] 才俊學校",
  "[沙田區] 救世軍田家炳學校",
  "[沙田區] 明愛樂群學校",
  "[沙田區] 明愛樂進學校",
  "[沙田區] 明愛馬鞍山中學",
  "[沙田區] 曾璧山中學",
  "[沙田區] 李寶椿聯合世界書院",
  "[沙田區] 東莞工商總會劉百樂中學",
  "[沙田區] 東莞工商總會張煌偉小學",
  "[沙田區] 東華三院冼次雲小學",
  "[沙田區] 東華三院邱金元中學",
  "[沙田區] 東華三院馮黃鳳亭中學",
  "[沙田區] 東華三院黃鳳翎中學",
  "[沙田區] 林大輝中學",
  "[沙田區] 梁文燕紀念中學(沙田)",
  "[沙田區] 樂善堂楊葛小琳中學",
  "[沙田區] 樂道中學",
  "[沙田區] 沙田公立學校",
  "[沙田區] 沙田圍胡素貞博士紀念學校",
  "[沙田區] 沙田培英中學",
  "[沙田區] 沙田學院",
  "[沙田區] 沙田官立中學",
  "[沙田區] 沙田官立小學",
  "[沙田區] 沙田小學",
  "[沙田區] 沙田崇真中學",
  "[沙田區] 沙田崇真學校",
  "[沙田區] 沙田循道衛理中學",
  "[沙田區] 沙田循道衛理小學",
  "[沙田區] 沙田蘇浙公學",
  "[沙田區] 浸信會呂明才中學",
  "[沙田區] 浸信會呂明才小學",
  "[沙田區] 浸信會沙田圍呂明才小學",
  "[沙田區] 港專成人教育中心(沙田官立中學)",
  "[沙田區] 潮州會館中學",
  "[沙田區] 聖公會主風小學",
  "[沙田區] 聖公會曾肇添中學",
  "[沙田區] 聖公會林裘謀中學",
  "[沙田區] 聖公會馬鞍山主風小學",
  "[沙田區] 聖母無玷聖心學校",
  "[沙田區] 聖母無玷聖心書院",
  "[沙田區] 聖羅撒書院",
  "[沙田區] 胡素貞博士紀念學校",
  "[沙田區] 賽馬會體藝中學",
  "[沙田區] 路德會梁鉅鏐小學",
  "[沙田區] 路德會沙田夜校",
  "[沙田區] 迦密愛禮信小學",
  "[沙田區] 青年會書院",
  "[沙田區] 香港中國婦女會馮堯敬紀念中學",
  "[沙田區] 香港中文大學校友會聯會張煊昌學校",
  "[沙田區] 香港中文大學校友會聯會陳震夏中學",
  "[沙田區] 香港九龍塘基督教中華宣道會台山陳元喜小學",
  "[沙田區] 香港九龍塘基督教中華宣道會鄭榮之中學",
  "[沙田區] 香港九龍塘基督教中華宣道會陳元喜小學",
  "[沙田區] 香港浸會大學附屬學校王錦輝中小學",
  "[沙田區] 香港神託會培基書院",
  "[沙田區] 香港紅十字會醫院學校",
  "[沙田區] 香港耀能協會高福耀紀念學校",
  "[沙田區] 香港聖瑪加利女書院",
  "[沙田區] 香港道教聯合會純陽小學",
  "[沙田區] 馬鞍山崇真中學",
  "[沙田區] 馬鞍山循道衛理小學",
  "[沙田區] 馬鞍山聖若瑟中學",
  "[沙田區] 馬鞍山聖若瑟小學",
  "[沙田區] 馬鞍山靈糧小學",
  "[油尖旺區] 世界龍岡學校劉皇發中學",
  "[油尖旺區] 中華基督教會協和小學",
  "[油尖旺區] 中華基督教會基全小學",
  "[油尖旺區] 中華基督教會望覺堂啓愛學校",
  "[油尖旺區] 中華基督教會灣仔堂基道小學",
  "[油尖旺區] 中華基督教會銘基書院",
  "[油尖旺區] 九龍三育中學(初中)",
  "[油尖旺區] 九龍三育中學(高中)",
  "[油尖旺區] 九龍婦女福利會李炳紀念學校",
  "[油尖旺區] 伊利沙伯中學",
  "[油尖旺區] 佐敦道官立小學",
  "[油尖旺區] 保良局莊啓程預科書院",
  "[油尖旺區] 保良局陳守仁小學",
  "[油尖旺區] 優才(楊殷有娣)書院",
  "[油尖旺區] 嘉諾撒聖瑪利學校",
  "[油尖旺區] 嘉諾撒聖瑪利書院",
  "[油尖旺區] 基督教香港信義會信義中學",
  "[油尖旺區] 塘尾道官立小學",
  "[油尖旺區] 大角嘴天主教小學",
  "[油尖旺區] 大角嘴天主教小學(海帆道)",
  "[油尖旺區] 天主教新民書院",
  "[油尖旺區] 官立嘉道理爵士中學(西九龍)",
  "[油尖旺區] 廣東道官立小學",
  "[油尖旺區] 循道中學",
  "[油尖旺區] 循道學校",
  "[油尖旺區] 德信學校",
  "[油尖旺區] 拔萃女小學",
  "[油尖旺區] 拔萃女書院",
  "[油尖旺區] 東莞同鄉會方樹泉學校",
  "[油尖旺區] 東華三院羅裕積小學",
  "[油尖旺區] 油蔴地天主教小學",
  "[油尖旺區] 油蔴地天主教小學(海泓道)",
  "[油尖旺區] 油蔴地街坊會學校",
  "[油尖旺區] 港九潮州公會中學",
  "[油尖旺區] 港專成人教育中心(伊利沙伯中學)",
  "[油尖旺區] 真光女書院",
  "[油尖旺區] 聖公會基榮小學",
  "[油尖旺區] 聖公會諸聖中學",
  "[油尖旺區] 聖芳濟書院",
  "[油尖旺區] 聖迦利亞書院(九龍)",
  "[油尖旺區] 華仁書院(九龍)",
  "[油尖旺區] 路德會沙崙學校",
  "[油尖旺區] 香港凱莉山學校",
  "[油尖旺區] 香港管理專業協會李國寶中學",
  "[油尖旺區] 香港紅十字會醫院學校",
  "[油尖旺區] 香港道爾頓學校",
  "[油尖旺區] 鮮魚行學校",
  "[油尖旺區] 麗澤中學",
  "[深水埗區] 中聖書院",
  "[深水埗區] 中華基督教會協和小學(長沙灣)",
  "[深水埗區] 中華基督教會銘賢書院",
  "[深水埗區] 九龍工業學校",
  "[深水埗區] 九龍禮賢學校",
  "[深水埗區] 五邑工商總會學校",
  "[深水埗區] 佛教大雄中學",
  "[深水埗區] 保良局唐乃勤初中書院",
  "[深水埗區] 保良局蔡繼有學校",
  "[深水埗區] 協同國際學校",
  "[深水埗區] 啟基學校",
  "[深水埗區] 地利亞修女紀念學校(吉利徑)",
  "[深水埗區] 地利亞修女紀念學校(百老匯)",
  "[深水埗區] 地利亞修女紀念預科夜校(百老匯)",
  "[深水埗區] 地利亞英文小學暨幼稚園",
  "[深水埗區] 基督教崇真中學",
  "[深水埗區] 基督教香港信義會深信學校",
  "[深水埗區] 大坑東宣道小學",
  "[深水埗區] 天主教南華中學",
  "[深水埗區] 天主教善導小學",
  "[深水埗區] 宣道國際學校",
  "[深水埗區] 寶血會上智英文書院",
  "[深水埗區] 寶血會嘉靈學校",
  "[深水埗區] 崇正中學",
  "[深水埗區] 崇真小學暨幼稚園",
  "[深水埗區] 嶺南大學香港同學會小學",
  "[深水埗區] 廠商會中學",
  "[深水埗區] 德貞女子中學",
  "[深水埗區] 德雅中學",
  "[深水埗區] 德雅小學",
  "[深水埗區] 惠僑英文中學",
  "[深水埗區] 慈恩學校",
  "[深水埗區] 新會商會港青基信學校",
  "[深水埗區] 旅港開平商會學校",
  "[深水埗區] 明愛賽馬會樂仁學校",
  "[深水埗區] 李鄭屋官立小學",
  "[深水埗區] 東華三院張明添中學",
  "[深水埗區] 東華三院群芳啓智學校",
  "[深水埗區] 深水埔街坊福利會小學",
  "[深水埗區] 深水埗官立小學",
  "[深水埗區] 港專成人教育中心(九龍工業學校)",
  "[深水埗區] 滙基書院",
  "[深水埗區] 瑪利亞夜書院",
  "[深水埗區] 瑪利亞書院",
  "[深水埗區] 瑪利諾神父教會學校",
  "[深水埗區] 瑪利諾神父教會學校(小學部)",
  "[深水埗區] 白田天主教小學",
  "[深水埗區] 福榮街官立小學",
  "[深水埗區] 聖公會基愛小學",
  "[深水埗區] 聖公會基福小學",
  "[深水埗區] 聖公會聖多馬小學",
  "[深水埗區] 聖公會聖安德烈小學",
  "[深水埗區] 聖公會聖紀文小學",
  "[深水埗區] 聖公會聖馬利亞堂莫慶堯中學",
  "[深水埗區] 聖方濟各英文小學",
  "[深水埗區] 聖方濟愛德小學",
  "[深水埗區] 聖母玫瑰書院",
  "[深水埗區] 聖瑪加利男女英文中小學",
  "[深水埗區] 聖道弘爵國際學校",
  "[深水埗區] 英華小學",
  "[深水埗區] 英華書院",
  "[深水埗區] 荔枝角天主教小學",
  "[深水埗區] 路德會協同中學",
  "[深水埗區] 路德會救主學校",
  "[深水埗區] 長沙灣天主教英文中學",
  "[深水埗區] 陳樹渠紀念中學",
  "[深水埗區] 香島中學",
  "[深水埗區] 香港四邑商工總會新會商會學校",
  "[深水埗區] 香港四邑商工總會黃棣珊紀念中學",
  "[深水埗區] 香港心理衞生會-臻和學校",
  "[深水埗區] 香港扶幼會-許仲繩紀念學校",
  "[深水埗區] 香港扶幼會則仁中心學校",
  "[深水埗區] 香港紅十字會醫院學校",
  "[灣仔區] HONGKONG JAPANESE SCHOOL",
  "[灣仔區] LYC'EE FRANCAIS INTL (FRENCH INTL SCH)",
  "[灣仔區] 中華基督教會公理高中書院",
  "[灣仔區] 何東中學",
  "[灣仔區] 佛教黃焯菴小學",
  "[灣仔區] 佛教黃鳳翎中學",
  "[灣仔區] 保良局建造商會學校",
  "[灣仔區] 保良局金銀業貿易場張凝文學校",
  "[灣仔區] 北角協同中學",
  "[灣仔區] 北角官立小學(雲景道)",
  "[灣仔區] 匡智獅子會晨崗學校",
  "[灣仔區] 嘉諾撒聖方濟各學校",
  "[灣仔區] 嘉諾撒聖方濟各書院",
  "[灣仔區] 孔聖堂中學",
  "[灣仔區] 官立嘉道理爵士小學",
  "[灣仔區] 寶血小學",
  "[灣仔區] 寶覺小學",
  "[灣仔區] 晉德學校",
  "[灣仔區] 李陞大坑學校",
  "[灣仔區] 東華三院李潤田紀念中學",
  "[灣仔區] 東華三院李賜豪小學",
  "[灣仔區] 港專成人教育中心(何東中學)",
  "[灣仔區] 玫瑰崗學校",
  "[灣仔區] 瑪利曼中學",
  "[灣仔區] 瑪利曼小學",
  "[灣仔區] 番禺會所華仁小學",
  "[灣仔區] 白普理小學",
  "[灣仔區] 皇仁書院",
  "[灣仔區] 皇仁舊生會夜中學",
  "[灣仔區] 聖保祿中學",
  "[灣仔區] 聖保祿天主教小學",
  "[灣仔區] 聖保祿學校",
  "[灣仔區] 聖保祿學校(小學部)",
  "[灣仔區] 聖公會聖雅各小學",
  "[灣仔區] 聖公會鄧肇堅中學",
  "[灣仔區] 聖若瑟小學",
  "[灣仔區] 賽馬會匡智學校",
  "[灣仔區] 軒尼詩道官立小學",
  "[灣仔區] 軒尼詩道官立小學(銅鑼灣)",
  "[灣仔區] 鄧肇堅維多利亞官立中學",
  "[灣仔區] 香港真光中學",
  "[灣仔區] 香港華仁書院",
  "[灣仔區] 香港鄧鏡波書院",
  "[灣仔區] 高主教書院小學部",
  "[荃灣區] 中華基督教會全完第一小學",
  "[荃灣區] 中華基督教會基慧小學",
  "[荃灣區] 中華基督教會基慧小學(馬灣)",
  "[荃灣區] 仁濟醫院林百欣中學",
  "[荃灣區] 保良局姚連生中學",
  "[荃灣區] 保良局李城璧中學",
  "[荃灣區] 博愛醫院歷屆總理聯誼會梁省德中學",
  "[荃灣區] 可觀自然教育中心暨天文館",
  "[荃灣區] 可風中學(嗇色園主辦)",
  "[荃灣區] 嗇色園主辦可信學校",
  "[荃灣區] 天主教石鐘山紀念小學",
  "[荃灣區] 天佑小學",
  "[荃灣區] 寶安商會王少清中學",
  "[荃灣區] 寶血會伍季明紀念學校",
  "[荃灣區] 寶血會思源學校",
  "[荃灣區] 廖寶珊紀念書院",
  "[荃灣區] 柴灣角天主教小學",
  "[荃灣區] 梨木樹天主教小學",
  "[荃灣區] 海壩街官立小學",
  "[荃灣區] 深井天主教小學",
  "[荃灣區] 港專成人教育中心(荃灣官立中學)",
  "[荃灣區] 玫瑰蕾小學",
  "[荃灣區] 紡織學會美國商會胡漢輝中學",
  "[荃灣區] 聖公會主愛小學(梨木樹)",
  "[荃灣區] 聖公會李炳中學",
  "[荃灣區] 荃灣公立何傳耀紀念中學",
  "[荃灣區] 荃灣公立何傳耀紀念小學",
  "[荃灣區] 荃灣天主教小學",
  "[荃灣區] 荃灣官立中學",
  "[荃灣區] 荃灣官立小學",
  "[荃灣區] 荃灣潮州公學",
  "[荃灣區] 荃灣聖芳濟中學",
  "[荃灣區] 路德會呂明才中學",
  "[荃灣區] 路德會聖十架學校",
  "[荃灣區] 靈光小學",
  "[荃灣區] 香港浸信會聯會小學",
  "[荃灣區] 香港紅十字會醫院學校",
  "[荃灣區] 香港道教聯合會圓玄學院石圍角小學",
  "[葵青區] 三水同鄉會劉本章學校",
  "[葵青區] 中華傳道會呂明才小學",
  "[葵青區] 中華傳道會安柱中學",
  "[葵青區] 中華傳道會李賢堯紀念中學",
  "[葵青區] 中華傳道會許大同學校",
  "[葵青區] 中華基督教會全完中學",
  "[葵青區] 中華基督教會全完第二小學",
  "[葵青區] 中華基督教會基真小學",
  "[葵青區] 中華基督教會燕京書院",
  "[葵青區] 亞斯理衞理小學",
  "[葵青區] 仁濟醫院趙曾學韞小學",
  "[葵青區] 佛教善德英文中學",
  "[葵青區] 佛教林炳炎紀念學校(香港佛教聯合會主辦)",
  "[葵青區] 佛教林金殿紀念小學",
  "[葵青區] 佛教葉紀南紀念中學",
  "[葵青區] 保祿六世書院",
  "[葵青區] 保良局世德小學",
  "[葵青區] 保良局羅傑承(一九八三)中學",
  "[葵青區] 保良局陳溢小學",
  "[葵青區] 保良局陳百強伉儷青衣學校",
  "[葵青區] 匡智張玉瓊晨輝學校",
  "[葵青區] 地利亞(閩僑)英文小學",
  "[葵青區] 基督教香港信義會葵盛信義學校",
  "[葵青區] 天主教慈幼會伍少梅中學",
  "[葵青區] 天主教母佑會蕭明中學",
  "[葵青區] 嶺南鍾榮光博士紀念中學",
  "[葵青區] 慈幼葉漢千禧小學",
  "[葵青區] 慈幼葉漢小學",
  "[葵青區] 明愛聖若瑟中學",
  "[葵青區] 李惠利中學",
  "[葵青區] 東華三院伍若瑜夫人紀念中學",
  "[葵青區] 東華三院吳祥川紀念中學",
  "[葵青區] 東華三院周演森小學",
  "[葵青區] 東華三院陳兆民中學",
  "[葵青區] 東華三院高可寧紀念小學",
  "[葵青區] 東華三院黃士心小學",
  "[葵青區] 柏立基教育學院校友會李一諤紀念學校",
  "[葵青區] 棉紡會中學",
  "[葵青區] 樂善堂梁植偉紀念中學",
  "[葵青區] 樂善堂顧超文中學",
  "[葵青區] 獅子會中學",
  "[葵青區] 皇仁舊生會中學",
  "[葵青區] 石籬天主教中學",
  "[葵青區] 石籬天主教小學",
  "[葵青區] 石籬聖若望天主教小學",
  "[葵青區] 祖堯天主教小學",
  "[葵青區] 聖公會主恩小學",
  "[葵青區] 聖公會主愛小學",
  "[葵青區] 聖公會仁立小學",
  "[葵青區] 聖公會仁立紀念小學",
  "[葵青區] 聖公會何澤芸小學",
  "[葵青區] 聖公會林護紀念中學",
  "[葵青區] 聖公會青衣主恩小學",
  "[葵青區] 聖公會青衣邨何澤芸小學",
  "[葵青區] 荃灣商會學校",
  "[葵青區] 荔景天主教中學",
  "[葵青區] 葵涌循道中學",
  "[葵青區] 葵涌蘇浙公學",
  "[葵青區] 裘錦秋中學(葵涌)",
  "[葵青區] 路德會啓聾學校",
  "[葵青區] 迦密愛禮信中學",
  "[葵青區] 郭怡雅神父紀念學校",
  "[葵青區] 青衣商會小學",
  "[葵青區] 順德聯誼總會李兆基中學",
  "[葵青區] 香港四邑商工總會陳南昌紀念中學",
  "[葵青區] 香港四邑商工總會陳南昌紀念學校",
  "[葵青區] 香港紅十字會醫院學校",
  "[葵青區] 香港耀能協會羅怡基紀念學校",
  "[葵青區] 香港道教聯合會圓玄學院第一中學",
  "[西貢區] 仁愛堂田家炳小學",
  "[西貢區] 仁濟醫院王華湘中學",
  "[西貢區] 仁濟醫院陳耀星小學",
  "[西貢區] 仁濟醫院靚次伯紀念中學",
  "[西貢區] 佛教志蓮小學",
  "[西貢區] 保良局甲子何玉清中學",
  "[西貢區] 保良局羅氏基金中學",
  "[西貢區] 保良局陸慶濤小學",
  "[西貢區] 保良局馮晴紀念小學",
  "[西貢區] 保良局黃永樹小學",
  "[西貢區] 優才(楊殷有娣)書院",
  "[西貢區] 匡智翠林晨崗學校",
  "[西貢區] 匯知中學",
  "[西貢區] 博愛醫院八十週年鄧英喜中學",
  "[西貢區] 博愛醫院陳國威小學",
  "[西貢區] 啓思中學",
  "[西貢區] 基督教宣道會宣基中學",
  "[西貢區] 基督教宣道會宣基小學",
  "[西貢區] 基督教神召會梁省德小學",
  "[西貢區] 天主教聖安德肋小學",
  "[西貢區] 天主教鳴遠中學",
  "[西貢區] 威靈頓教育機構張沛松紀念中學",
  "[西貢區] 寶覺中學",
  "[西貢區] 將軍澳培智學校",
  "[西貢區] 將軍澳天主教小學",
  "[西貢區] 將軍澳官立中學",
  "[西貢區] 將軍澳官立小學",
  "[西貢區] 將軍澳循道衛理小學",
  "[西貢區] 將軍澳香島中學",
  "[西貢區] 播道書院",
  "[西貢區] 新界西貢坑口區鄭植之中學",
  "[西貢區] 景嶺書院",
  "[西貢區] 景林天主教小學",
  "[西貢區] 東華三院呂潤財紀念中學",
  "[西貢區] 東華三院王余家潔紀念小學",
  "[西貢區] 樂善堂劉德學校",
  "[西貢區] 樹宏學校",
  "[西貢區] 清水灣小學",
  "[西貢區] 港澳信義會小學",
  "[西貢區] 港澳信義會慕德中學",
  "[西貢區] 港澳信義會明道小學",
  "[西貢區] 聖公會將軍澳基德小學",
  "[西貢區] 西貢中心李少欽紀念學校",
  "[西貢區] 西貢崇真天主教學校(中學部)",
  "[西貢區] 西貢崇真天主教學校(小學部)",
  "[西貢區] 迦密主恩中學",
  "[西貢區] 靈實恩光學校",
  "[西貢區] 順德聯誼總會梁潔華小學",
  "[西貢區] 順德聯誼總會鄭裕彤中學",
  "[西貢區] 香海正覺蓮社佛教正覺中學",
  "[西貢區] 香海正覺蓮社佛教黃藻森學校",
  "[西貢區] 香港學堂國際學校",
  "[西貢區] 香港復臨學校",
  "[西貢區] 香港復臨學校(小學部)",
  "[西貢區] 香港紅十字會醫院學校",
  "[西貢區] 香港華人基督教聯會真道書院",
  "[西貢區] 香港道教聯合會圓玄學院第三中學",
  "[西貢區] 馬錦明慈善基金馬陳端喜紀念中學",
  "[西貢區] 馬錦明慈善基金馬陳端喜紀念夜校",
  "[觀塘區] KELLETT SCHOOL",
  "[觀塘區] NORD ANGLIA INTERNATIONAL SCHOOL",
  "[觀塘區] NORD ANGLIA INTERNATIONAL SCHOOL, HK",
  "[觀塘區] 中華基督教會基智中學",
  "[觀塘區] 中華基督教會基法小學",
  "[觀塘區] 中華基督教會基法小學(油塘)",
  "[觀塘區] 中華基督教會基順學校",
  "[觀塘區] 中華基督教會蒙民偉書院",
  "[觀塘區] 九龍灣聖若翰天主教小學",
  "[觀塘區] 五邑司徒浩中學",
  "[觀塘區] 仁濟醫院羅陳楚思中學",
  "[觀塘區] 佐敦谷聖若瑟天主教小學",
  "[觀塘區] 佛教何南金中學",
  "[觀塘區] 佛教慈敬學校",
  "[觀塘區] 地利亞修女紀念學校(利瑪竇)",
  "[觀塘區] 地利亞修女紀念學校(協和)",
  "[觀塘區] 地利亞修女紀念學校(月華)",
  "[觀塘區] 坪石天主教小學",
  "[觀塘區] 基督教中國佈道會聖道學校",
  "[觀塘區] 基督教中國佈道會聖道迦南書院",
  "[觀塘區] 基督教宣道會宣基小學(坪石)",
  "[觀塘區] 基督教聖約教會堅樂中學",
  "[觀塘區] 基督教聖約教會堅樂小學",
  "[觀塘區] 基督教聖約教會堅樂第二小學",
  "[觀塘區] 天主教佑華小學",
  "[觀塘區] 天主教普照中學",
  "[觀塘區] 天主教柏德學校",
  "[觀塘區] 寧波公學",
  "[觀塘區] 寧波第二中學",
  "[觀塘區] 慕光英文書院",
  "[觀塘區] 新生命教育協會呂郭碧鳳中學",
  "[觀塘區] 梁式芝書院",
  "[觀塘區] 樂善堂楊仲明學校",
  "[觀塘區] 樂華天主教小學",
  "[觀塘區] 浸信宣道會呂明才小學",
  "[觀塘區] 滙基書院(東九龍)",
  "[觀塘區] 瑪利諾中學",
  "[觀塘區] 福建中學",
  "[觀塘區] 福建中學附屬學校",
  "[觀塘區] 秀明小學",
  "[觀塘區] 秀茂坪天主教小學",
  "[觀塘區] 聖傑靈女子中學",
  "[觀塘區] 聖公會九龍灣基樂小學",
  "[觀塘區] 聖公會基孝中學",
  "[觀塘區] 聖公會基樂小學",
  "[觀塘區] 聖公會基顯小學",
  "[觀塘區] 聖公會德田李兆強小學",
  "[觀塘區] 聖公會李兆強小學",
  "[觀塘區] 聖公會梁季彝中學",
  "[觀塘區] 聖公會油塘基顯小學",
  "[觀塘區] 聖公會聖約翰小學",
  "[觀塘區] 聖安當女書院",
  "[觀塘區] 聖安當小學",
  "[觀塘區] 聖愛德華天主教小學",
  "[觀塘區] 聖若瑟英文中學",
  "[觀塘區] 聖若瑟英文小學",
  "[觀塘區] 聖若翰天主教小學",
  "[觀塘區] 聖言中學",
  "[觀塘區] 藍田循道衛理小學",
  "[觀塘區] 藍田聖保祿中學",
  "[觀塘區] 觀塘功樂官立中學",
  "[觀塘區] 觀塘官立中學",
  "[觀塘區] 觀塘官立小學",
  "[觀塘區] 觀塘官立小學(秀明道)",
  "[觀塘區] 觀塘瑪利諾書院",
  "[觀塘區] 路德會聖馬太學校(秀茂坪)",
  "[觀塘區] 迦密梁省德學校",
  "[觀塘區] 閩僑小學",
  "[觀塘區] 順利天主教中學",
  "[觀塘區] 香港布廠商會朱石麟中學",
  "[觀塘區] 香港扶幼會盛德中心學校",
  "[觀塘區] 香港紅十字會醫院學校",
  "[觀塘區] 香港紅十字會雅麗珊郡主學校",
  "[觀塘區] 香港聖公會何明華會督中學",
  "[觀塘區] 香港道教聯合會圓玄學院陳呂重德紀念學校",
  "[觀塘區] 香港道教聯合會雲泉學校",
  "[觀塘區] 香港道教聯合會青松中學",
  "[觀塘區] 高雷中學",
  "[離島區] DISCOVERY BAY INTERNATIONAL SCHOOL",
  "[離島區] LANTAU INTERNATIONAL SCHOOL",
  "[離島區] MUI WO OWLS SCHOOL",
  "[離島區] 中華基督教會大澳小學",
  "[離島區] 中華基督教會長洲堂錦江小學",
  "[離島區] 佛教慧因法師紀念中學",
  "[離島區] 佛教筏可紀念中學",
  "[離島區] 保良局馬錦明夫人章馥仙中學",
  "[離島區] 南丫北段公立小學",
  "[離島區] 嗇色園主辦可譽中學暨可譽小學",
  "[離島區] 國民學校",
  "[離島區] 基督教正生書院",
  "[離島區] 寶安商會溫浩根小學",
  "[離島區] 弘志學校",
  "[離島區] 救世軍林拔中紀念學校",
  "[離島區] 明愛華德中書院",
  "[離島區] 明愛陳震夏郊野學園",
  "[離島區] 智新書院",
  "[離島區] 杯澳公立學校",
  "[離島區] 東涌天主教學校",
  "[離島區] 東灣莫羅瑞華學校",
  "[離島區] 梅窩學校",
  "[離島區] 港青基信書院",
  "[離島區] 聖公會偉倫小學",
  "[離島區] 聖家學校",
  "[離島區] 長洲官立中學",
  "[離島區] 長洲聖心學校",
  "[離島區] 靈糧堂怡文中學",
  "[離島區] 靈糧堂秀德小學",
  "[離島區] 青松侯寶垣小學",
  "[離島區] 香港教育工作者聯會黃楚標中學",
  "[離島區] 香港教育工作者聯會黃楚標學校",
  "[黃大仙區] 中華基督教會協和書院",
  "[黃大仙區] 中華基督教會基協中學",
  "[黃大仙區] 中華基督教會基慈小學",
  "[黃大仙區] 中華基督教會基華小學",
  "[黃大仙區] 中華基督教會扶輪中學",
  "[黃大仙區] 五旬節聖潔會永光書院",
  "[黃大仙區] 伊斯蘭鮑伯濤紀念小學",
  "[黃大仙區] 佛教孔仙洲紀念中學",
  "[黃大仙區] 佛教志蓮中學",
  "[黃大仙區] 保良局何蔭棠中學",
  "[黃大仙區] 保良局第一張永慶中學",
  "[黃大仙區] 保良局錦泰小學",
  "[黃大仙區] 保良局陳南昌夫人小學",
  "[黃大仙區] 保良局陳麗玲(百周年)學校",
  "[黃大仙區] 可立中學(嗇色園主辦)",
  "[黃大仙區] 嗇色園主辦可立小學",
  "[黃大仙區] 嘉諾撒小學",
  "[黃大仙區] 嘉諾撒小學(新蒲崗)",
  "[黃大仙區] 國際基督教優質音樂中學暨小學",
  "[黃大仙區] 天主教伍華中學",
  "[黃大仙區] 天主教伍華小學",
  "[黃大仙區] 天主教博智小學",
  "[黃大仙區] 孔教學院大成小學",
  "[黃大仙區] 彩虹邨天主教英文中學",
  "[黃大仙區] 彩雲聖若瑟小學",
  "[黃大仙區] 德愛中學",
  "[黃大仙區] 德望學校",
  "[黃大仙區] 德望小學暨幼稚園",
  "[黃大仙區] 慈雲山天主教小學",
  "[黃大仙區] 慈雲山聖文德天主教小學",
  "[黃大仙區] 救世軍卜維廉中學",
  "[黃大仙區] 文理書院(九龍)",
  "[黃大仙區] 明愛培立學校",
  "[黃大仙區] 李求恩紀念中學",
  "[黃大仙區] 樂善堂余近卿中學",
  "[黃大仙區] 樂善堂王仲銘中學",
  "[黃大仙區] 浸信會天虹小學",
  "[黃大仙區] 潔心林炳炎中學",
  "[黃大仙區] 獻主會溥仁小學",
  "[黃大仙區] 真鐸學校",
  "[黃大仙區] 神召會夜中學",
  "[黃大仙區] 神召會馬理信書院",
  "[黃大仙區] 福德學校",
  "[黃大仙區] 禮賢會恩慈學校",
  "[黃大仙區] 聖公會基德小學",
  "[黃大仙區] 聖公會聖本德中學",
  "[黃大仙區] 聖博德天主教小學(蒲崗村道)",
  "[黃大仙區] 聖博德學校",
  "[黃大仙區] 聖文德天主教小學",
  "[黃大仙區] 聖文德書院",
  "[黃大仙區] 聖母小學",
  "[黃大仙區] 聖母書院",
  "[黃大仙區] 華德學校",
  "[黃大仙區] 香港神託會培敦中學",
  "[黃大仙區] 香港紅十字會瑪嘉烈戴麟趾學校",
  "[黃大仙區] 黃大仙天主教小學",
  "[黃大仙區] 黃大仙官立小學",
  "[黃大仙區] 龍翔官立中學"
];
// append wildcard
schools.unshift("其他");

export default schools.map((school, idx) => ({ value: idx, label: school }));
