import React, { PureComponent } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Error from "./Error";

class Mobile extends PureComponent {
  render() {
    const {
      field,
      values,
      errors,
      touched,
      handleChange,
      handleBlur
    } = this.props;
    return (
      <FormGroup>
        <Label htmlFor={field}>投選該歷史人物的原因</Label>
        <Input
          id={field}
          type="textarea"
          value={values[field]}
          onChange={handleChange}
          onBlur={handleBlur}
          rows={3}
        />
        <Error field={field} errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default Mobile;
