import React from "react";

// use the CSS here if not using Bootstrap
// [Responsive IFRAMEs — The Right Way (CSS Only)! | Ben Marshall](https://benmarshall.me/responsive-iframes/)

export default ({ src, title = "" }) => {
  return (
    <div className="embed-responsive embed-responsive-16by9">
      <iframe
        src={src}
        title={title}
        className="embed-responsive-item"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};
