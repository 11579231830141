import React, { PureComponent } from "react";
import { FormGroup, Label, Input, FormText } from "reactstrap";
import Error from "./Error";

class Mobile extends PureComponent {
  render() {
    const {
      field,
      values,
      errors,
      touched,
      handleChange,
      handleBlur
    } = this.props;
    return (
      <FormGroup>
        <Label htmlFor={field}>手提電話</Label>
        <Input
          id={field}
          placeholder="請輸入可以接收短訊的手提電話號碼"
          type="tel"
          value={values[field]}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <FormText className="disclaimer">
          輸入的手提電話只供是次活動投票之用(發出OTP和確保選票的唯一性)，活動完結後會立即銷毁
        </FormText>
        <FormText>
          內地及澳門手提電話號碼，請加上<code>+86</code>或<code>+853</code>字首
        </FormText>
        <Error field={field} errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default Mobile;
