import React, { PureComponent } from "react";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import DebugNav from "./components/DebugNav";
import Header from "./components/Header";
import Footer from "./components/Footer";

import CodeVoting from "./pages/CodeVoting";
import Home from "./pages/Home";
import Voting from "./pages/Voting";

class App extends PureComponent {
  render() {
    return (
      <Container className="App">
        <Header />
        <Router>
          <>
            <DebugNav />
            <Switch>
              <Redirect from="/g" to="/" />
              <Route exact path="/" component={Home} />
              {/* <Route path="/voting" component={Voting} /> */}
              <Redirect from="/voting" to="/" />
              {/* <Route exact path="/g" component={CodeVoting} /> */}
              <Redirect from="/g" to="/" />
            </Switch>
          </>
        </Router>
        <Footer />
      </Container>
    );
  }
}

export default App;
