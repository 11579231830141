import React, { PureComponent } from "react";
import { FormGroup, Label, Input, FormText, Row, Col } from "reactstrap";
import Error from "./Error";
import "./Choice.css";

const choices = [
  {
    value: 1,
    name: "蔡元培",
    image: "./images/1.jpg"
  },
  {
    value: 2,
    name: "魯迅",
    image: "./images/2.jpg"
  },
  {
    value: 3,
    name: "陳獨秀",
    image: "./images/3.jpg"
  },
  {
    value: 4,
    name: "顧維鈞",
    image: "./images/4.jpg"
  },
  {
    value: 5,
    name: "胡適",
    image: "./images/5.jpg"
  }
];

class Choice extends PureComponent {
  render() {
    const {
      field,
      values,
      errors,
      touched,
      handleChange,
      handleBlur
    } = this.props;
    return (
      <FormGroup>
        <Row form style={{ margin: "0" }}>
          <Label htmlFor={field}>年度歷史人物候選人</Label>
        </Row>
        <Row form style={{ margin: "0" }}>
          <FormText>
            請選擇下列一位心儀的年度中國歷史人物，然後按「提交」，即完成投票！
            <br />* 有關五位候選人物簡介，請參閱本中心網頁：
            <br className="rwd-break-xl" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cnhe-hk.org/五位候選歷史人物簡介2019/"
            >
              https://www.cnhe-hk.org/五位候選歷史人物簡介2019/
            </a>
          </FormText>
        </Row>
        <Row form style={{ margin: "0" }}>
          {choices.map((choice, idx) => {
            // eslint-disable-next-line
            const checked = values.choice == choice.value;
            // const src = checked
            //   ? "./images/radio_checked.svg"
            //   : "./images/radio_unchecked.svg";
            const src = checked
              ? "./images/check_box_checked.svg"
              : "./images/check_box_unchecked.svg";
            return (
              <Col lg={4} xs={6} key={idx}>
                <Label check className="ChoiceElement">
                  <Input
                    type="radio"
                    name={field}
                    value={choice.value}
                    checked={checked}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="card">
                    <img
                      src={choice.image}
                      alt={choice.name}
                      className="poster"
                    />
                    <div className="label">
                      <img src={src} alt="check box" className="radio" />
                      {choice.name}
                    </div>
                  </div>
                </Label>
              </Col>
            );
          })}
        </Row>
        <Error field={field} errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default Choice;
