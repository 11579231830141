import React from "react";

export default props => {
  return (
    <div className="poster">
      <a target="_blank" rel="noopener noreferrer" href={props.src}>
        <img
          style={{
            width: "100%",
            boxShadow: "10px 10px 20px -5px rgba(0,0,0,0.75)"
          }}
          alt={props.alt || ""}
          src={props.src}
          title="點擊放大"
        />
      </a>
    </div>
  );
};
